/**
 * ROUTER REPORT_CHECKOUT HELPERS
 */
// The root path for this configuration microFrontend
/* eslint-disable import/prefer-default-export */
import { isUnity } from "compass-commons";

function getMiddlePath(url: string, first: string, second: string) {
  const regex = new RegExp(`/${first}(.*?)${second}`);
  return regex.exec(url)?.[1] || "/";
}

const url = !isStandalone
  ? window.location.href
  : "/report/dataexplorer/report-checkout";
const middlePath = getMiddlePath(url, "report", "dataexplorer");
export const ROOTPATH = `/report${middlePath}dataexplorer/report-checkout`;

export const DATA_EXPLORER_PATH = `/report${middlePath}dataexplorer`;

export const SOC_DATA_EXPLORER_PATH = `/data-explorer`;

export const getNavigateBackPath = (): string => {
  if (!isDMS) return `${window.location.origin}${SOC_DATA_EXPLORER_PATH}`;
  if (isUnity) return `${window.location.pathname}#${DATA_EXPLORER_PATH}`;
  return appConfig.COMPASS_DATA_EXPLORER_PATH;
};
