import React, { useEffect, useState } from "react";
import {
  Incident,
  IncidentStep,
  InfoFieldPhoto,
  InfoFieldTypes,
  ReportCart,
  ReportCartItemTypeEnum,
  useI18n,
} from "compass-commons";
import { BehaviorSubject } from "rxjs";
import { IAlertNotification } from "dms-lib";
import SideButtons from "../../commons/sideButtons/SideButtons";
import Download from "./Download";
import { ReportGenerateSource } from "../../../models/ReportGenerateSource";
import StateService from "../../../services/StateService";
import { useStateContext } from "../../../contexts/StateContext";
import { getDocument } from "../../../utils/Util";

const extractLinkStyle = (
  element: HTMLLinkElement | HTMLStyleElement
): string[] => {
  const { sheet } = element;
  if (sheet instanceof CSSStyleSheet && "cssRules" in sheet) {
    try {
      return Array.from(sheet.cssRules).map((rule) => rule.cssText);
    } catch (e) {
      return [];
    }
  }
  return [];
};

const extractEmotionStyles = (): string => {
  try {
    const styleElements =
      getDocument(document).querySelectorAll("[data-emotion]");

    return Array.from(styleElements)
      .flatMap((element) => {
        if (
          element instanceof HTMLStyleElement ||
          element instanceof HTMLLinkElement
        ) {
          return extractLinkStyle(element);
        }
        return [];
      })
      .join("\n");
  } catch (e) {
    return "";
  }
};

const appendElementsToHead = (
  documentHead: HTMLHeadElement,
  elements: Element[]
) => {
  elements.forEach((element) => {
    if (!element.getAttribute("data-emotion")) {
      documentHead.appendChild(element.cloneNode(true));
    }
  });
};

interface RightSideButtonsProps {
  generatingPdf: BehaviorSubject<ReportGenerateSource>;
  progressPdf: BehaviorSubject<number>;
  alertSubject: BehaviorSubject<IAlertNotification>;
  reportCart: ReportCart;
}

const RightSideButtons = ({
  generatingPdf,
  progressPdf,
  alertSubject,
  reportCart,
}: RightSideButtonsProps): JSX.Element => {
  const { t: translate } = useI18n();
  const [loadingPdf, setLoadingPdf] = useState(generatingPdf.value || false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const stateService: StateService = useStateContext();
  const { imagesData, incidentDetails } = stateService;
  const [images, setImages] = useState<InfoFieldPhoto[]>();
  const [incidents, setIncidents] = useState<Incident[]>();

  const createPdf = async () => {
    if (!generatingPdf?.value) {
      try {
        let styles = `
          html {
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
          }

          body {
            margin: 24px !important;
          }

          button[data-cr="dms-button"] {
            display: none !important;
          }

          .dms-cobalt .main-panel-list-div {
            overflow: hidden;
          }
          
          @media print {
            @page {
              size: A4 portrait;
              margin: 24px;
            }

            body {
              margin: 0 !important;
            }

            .operation-geninfo-card__wrapper {
              display: block !important;
            }
          }
        `;

        const emotionStyles = extractEmotionStyles();
        styles += emotionStyles;

        const wnd = window.open("", "_blank");
        if (isDMS) {
          const { children } =
            getDocument(document).getElementsByTagName("head")[0];
          appendElementsToHead(wnd.document.head, Array.from(children));
        } else {
          const stylesArray = Array.from(
            getDocument(document).querySelectorAll("style")
          );
          appendElementsToHead(wnd.document.head, stylesArray);
        }
        const styleSheet = wnd.document.createElement("style");
        styleSheet.innerHTML = styles;
        wnd.document.head.appendChild(styleSheet);
        const { children: mainPanelChildren } =
          getDocument(document).getElementById("main-panel");
        Array.from(mainPanelChildren).forEach((child) => {
          wnd.document.body.appendChild(child.cloneNode(true));
        });
        const wndHtml = wnd.document.getElementsByTagName("html")[0];
        wndHtml.classList.add(
          ...Array.from(document.getElementsByTagName("html")[0].classList)
        );
        if (!isDMS) {
          wndHtml.classList.add(
            ...Array.from(
              getDocument(document).querySelectorAll("div")[0].classList
            )
          );
        }
        wnd.document.close();
        wnd.print();
      } catch (error) {
        alertSubject.next({
          title: translate("pdf.failedCreateReport"),
        });
      }
    }
  };

  useEffect(() => {
    const generatingPDFSubscription = generatingPdf.subscribe((val) => {
      setLoadingPdf(val);
    });

    const imagesDataSubscription = imagesData.subscribe(
      (val: InfoFieldPhoto[]) => {
        setImages([...val]);
      }
    );

    const incidentDetailsSubscription = incidentDetails.subscribe(
      (val: Incident[]) => {
        setIncidents([...val]);
      }
    );

    return function cleanup() {
      generatingPDFSubscription.unsubscribe();
      imagesDataSubscription.unsubscribe();
      incidentDetailsSubscription.unsubscribe();
    };
  }, []);

  const getOperatorStepImages = (operatorStep: IncidentStep) => {
    return operatorStep.genericInfoList
      .filter(
        (infoStep) =>
          infoStep.infoFieldType === InfoFieldTypes.PHOTO &&
          (infoStep as InfoFieldPhoto).fileId
      )
      .map((photoStep) => (photoStep as InfoFieldPhoto).fileId);
  };

  const reportCartContainsDetailsCriteria = (): boolean => {
    return (
      reportCart?.elementList?.findIndex(
        (item) => item.type === ReportCartItemTypeEnum.DETAIL
      ) > -1
    );
  };

  useEffect(() => {
    if (
      reportCart?.elementList?.length > 0 &&
      !reportCartContainsDetailsCriteria()
    ) {
      setIsPageLoading(false);
      return;
    }

    if (!incidents || incidents?.length === 0) {
      setIsPageLoading(true);
      return;
    }

    const incidentsImages = incidents.reduce((imagesArray, incident) => {
      const incidentImages = incident.operatorSteps.reduce(
        (stepsImages, step) => {
          const stepImages = getOperatorStepImages(step);
          if (stepImages.length > 0) {
            stepsImages.push(...stepImages);
          }
          return stepsImages;
        },
        []
      );
      if (incidentImages.length > 0) {
        imagesArray.push(...incidentImages);
      }
      return imagesArray;
    }, []);

    let imageLoading = false;
    if (images.length === incidentsImages.length) {
      for (const image of images) {
        if (!incidentsImages.includes(image.fileId) || !image.mediaFile) {
          imageLoading = true;
          break;
        }
      }
    }

    setIsPageLoading(imageLoading);
  }, [reportCart, images, incidents]);

  return (
    <SideButtons orientation="right">
      <Download
        onClick={createPdf}
        disabled={!!loadingPdf || isPageLoading}
        loading={loadingPdf === "download"}
        loadingProgress={progressPdf}
      />
    </SideButtons>
  );
};

export default RightSideButtons;
