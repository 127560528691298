import React, { useEffect, useState } from "react";
import {
  LocalizationNS,
  sessionStorageIdentifiers,
  useGetOrganization,
  useGetToken,
  useI18n,
  withI18n,
} from "compass-commons";
import { getOrgIdFromToken } from "compass-shared-services";
import { AppTitle, AuthWrapper } from "dms-lib";
import "./App.css";
import "./styles.scss";
import { CircularProgress } from "@mui/material";
import StateService from "./services/StateService";
import UserManagerService from "./services/UserManagerService";
import { StateProvider } from "./contexts/StateContext";
import AppLayout from "./AppLayout";

const stateService: StateService = new StateService();
const { MFE_HELP_LINK_KEYWORD } = appConfig;

const App: React.FC = () => {
  const { t: translate } = useI18n();
  const { currentReportCart, generatingPdf, progressPdf } = stateService;

  const token = (() => {
    const theToken = useGetToken();
    return isStandalone ? localStorage.getItem("token") : theToken;
  })();

  const orgId = getOrgIdFromToken(token);
  const organizationId = useGetOrganization();

  const isSwitchingOrg = organizationId && organizationId !== orgId;
  const isLoggedInUser = Boolean(token);
  const [isAllowedUser, setIsAllowedUser] = useState<boolean>();

  useEffect(() => {
    const mainContextHelpKeyword = sessionStorage.getItem(
      sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD
    );

    if (mainContextHelpKeyword !== MFE_HELP_LINK_KEYWORD) {
      sessionStorage.setItem(
        sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD,
        MFE_HELP_LINK_KEYWORD
      );
    }
  }, []);

  const fetchAllowedUser = () => {
    UserManagerService.isUserAllowed().then((r) => {
      setIsAllowedUser(r);
    });
  };

  useEffect(() => {
    if (!isLoggedInUser) return;

    fetchAllowedUser();
  }, [isLoggedInUser]);

  useEffect(() => {
    if (isStandalone) return;

    fetchAllowedUser();
    currentReportCart.next(null);
  }, [orgId]);

  return (
    (isLoggedInUser || !!isStandalone) && (
      <StateProvider value={stateService}>
        {isDMS && (
          <AppTitle translate={translate} localizationNS={LocalizationNS} />
        )}
        <AuthWrapper
          isAuthorized={isAllowedUser || !!isStandalone}
          isLoading={isStandalone ? false : isAllowedUser === undefined}
          unauthorizedTitle={translate("unauthorized", {
            ns: LocalizationNS.SHARED,
          })}
          unauthorizedDescription={translate("unauthorizedContact", {
            ns: LocalizationNS.SHARED,
          })}
        >
          {isSwitchingOrg ? (
            <div className="report-checkout-loading-container">
              <CircularProgress />
            </div>
          ) : (
            <AppLayout
              currentReportCart={currentReportCart}
              generatingPdf={generatingPdf}
              progressPdf={progressPdf}
            />
          )}
        </AuthWrapper>
      </StateProvider>
    )
  );
};

export default withI18n(App);
