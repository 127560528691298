// Generic
import React, { useEffect, useMemo, useRef, useState } from "react";
// Styles
import "./genericInfoCardTask.module.css";
// Components
import {
  GenericInfoCardType,
  InfoField,
  InfoFieldPhoto,
  InfoFieldText,
  InfoFieldTypes,
  InfoFieldUrl,
} from "compass-commons";
import InfoFieldPhotoComponent from "./infoFieldsData/InfoFieldPhotoComponent";
import InfoFieldTextComponent from "./infoFieldsData/InfoFieldTextComponent";
import InfoFieldUrlComponent from "./infoFieldsData/InfoFieldUrlComponent";

export const InfoFieldToGenericInfoMapper = Object.freeze({
  TEXT: "DATA",
  PHOTO: "PHOTO",
  URL: "DATA",
});

interface GenericInfoCardTaskProps<T extends InfoField> {
  taskId: string;
  incidentId: string;
  infoFieldDTOs: T[];
}

const GenericInfoCardTask = <T extends InfoField>({
  taskId,
  incidentId,
  infoFieldDTOs,
}: GenericInfoCardTaskProps<T>): JSX.Element => {
  const imageWidthContainerRef = useRef(null);

  const [mediaData, setMediaData] = useState<{
    [key in GenericInfoCardType]: JSX.Element[];
  }>({
    [GenericInfoCardType.PHOTO]: [],
    [GenericInfoCardType.DATA]: [],
  });

  // Mapping from InfoField data to Component
  const InfoFieldToComponent = {
    [InfoFieldTypes.PHOTO]: (infoPhoto: InfoField) => (
      <InfoFieldPhotoComponent infoFieldPhoto={infoPhoto as InfoFieldPhoto} />
    ),
    [InfoFieldTypes.TEXT]: (infoText: InfoField) => (
      <InfoFieldTextComponent infoFieldText={infoText as InfoFieldText} />
    ),
    [InfoFieldTypes.URL]: (infoUrl: InfoField) => (
      <InfoFieldUrlComponent infoFieldUrl={infoUrl as InfoFieldUrl} />
    ),
  };

  const getPhotoData = useMemo(() => mediaData.PHOTO, [mediaData.PHOTO]);
  const getInfoData = useMemo(() => mediaData.DATA, [mediaData.DATA]);

  const prepareMediaData = () => {
    const tempMediaData = {
      [GenericInfoCardType.PHOTO]: [],
      [GenericInfoCardType.DATA]: [],
    };

    infoFieldDTOs.forEach((infof: InfoField) => {
      tempMediaData[InfoFieldToGenericInfoMapper[infof.infoFieldType]].push(
        InfoFieldToComponent[infof.infoFieldType](infof)
      );
    });

    setMediaData(tempMediaData);
  };

  useEffect(() => {
    prepareMediaData();
  }, []);

  const isGallery = (!getInfoData.length && "gallery") || "list";
  return (
    <div
      className="operation-geninfo-card__wrapper"
      id={`${incidentId}#${taskId}`}
    >
      <div className="operation-geninfo-card__container">
        {(getInfoData.length && (
          <div className="operation-geninfo-card__info">{...getInfoData}</div>
        )) ||
          null}
        {(getPhotoData.length && (
          <div
            ref={imageWidthContainerRef}
            className={`operation-geninfo-card__media operation-geninfo-card__images--${isGallery}`}
          >
            {...getPhotoData}
          </div>
        )) ||
          null}
      </div>
    </div>
  );
};

export default GenericInfoCardTask;
