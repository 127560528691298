import React from "react";
import "./mainPanel.module.css";
import { Divider } from "@mui/material";
import { ReportCart } from "compass-commons";
import { BehaviorSubject } from "rxjs";
import MainPanelHeader from "./mainPanelHeader/MainPanelHeader";
import MainPanelList from "./mainPanelList/MainPanelList";
import MainPanelFooter from "./mainPanelFooter/MainPanelFooter";

interface MainPanelProps {
  reportCartSubject: BehaviorSubject<ReportCart>;
}

const MainPanel = (props: MainPanelProps): JSX.Element => {
  const { reportCartSubject } = props;

  return (
    <div
      id="main-panel"
      className="main-panel compass-box-shadow compass-rounded-corner"
    >
      <MainPanelHeader reportCartSubject={reportCartSubject} />
      <Divider className="main-panel-divider" />
      <MainPanelList reportCartSubject={reportCartSubject} />
      <Divider className="main-panel-divider" />
      <MainPanelFooter reportCartSubject={reportCartSubject} />
    </div>
  );
};

export default MainPanel;
