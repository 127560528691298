import React from "react";
import { ArrowBackRounded } from "@mui/icons-material";
import "./buttons.module.css";
import SideActions from "./SideActions";
import StateService from "../../../services/StateService";
import { getNavigateBackPath } from "../../../routes/route";
import { useShellConfig } from "../../../hooks/useSoc";
import { MONITOR_SWITCH_TAB_DATA_EXPLORER } from "../../../utils/Constants";

const Back = (): JSX.Element => {
  const stateService: StateService = new StateService();
  const { currentReportCart } = stateService;
  const { shellConfig } = useShellConfig();

  const onClick = () => {
    currentReportCart.next(null);
    if (isDMS) {
      window.location.href = getNavigateBackPath();
    } else {
      shellConfig.eventBus.trigger(MONITOR_SWITCH_TAB_DATA_EXPLORER);
    }
  };
  return (
    <SideActions
      accessor={<span>Back</span>}
      accessorPosition="left"
      onClick={onClick}
      icon={<ArrowBackRounded color="primary" />}
    />
  );
};

export default Back;
