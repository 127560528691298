import React from "react";
import { ReportCart } from "compass-commons";
import { BehaviorSubject } from "rxjs";
import SideButtons from "../../commons/sideButtons/SideButtons";
import Back from "./Back";
import Delete from "./Delete";

interface LeftSideButtonsProps {
  currentReportCart: BehaviorSubject<ReportCart>;
}

const LeftSideButtons = ({
  currentReportCart,
}: LeftSideButtonsProps): JSX.Element => {
  return (
    <SideButtons orientation="left">
      <Back />
      <Delete cartSubject={currentReportCart} />
    </SideButtons>
  );
};

export default LeftSideButtons;
