import {
  RESPONSE_VIEW_TABS,
  ResponseViewEvents,
  SwitchTabEvent,
} from "@msicie/public-types";

// eslint-disable-next-line import/prefer-default-export
export const SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG =
  "b_showGenericErrorMessages";

export const MONITOR_SWITCH_TAB_DATA_EXPLORER: SwitchTabEvent = {
  type: ResponseViewEvents.SWITCH_TAB,
  payload: RESPONSE_VIEW_TABS.DATA_EXPLORER,
};
