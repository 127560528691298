import { BehaviorSubject } from "rxjs";
import { Incident, InfoFieldPhoto, ReportCart } from "compass-commons";
import { IAlertNotification } from "dms-lib";
import { ReportGenerateSource } from "../models/ReportGenerateSource";

class StateService {
  public currentReportCart: BehaviorSubject<ReportCart>;

  public alertSubject: BehaviorSubject<IAlertNotification>;

  public generatingPdf: BehaviorSubject<ReportGenerateSource>;

  public progressPdf: BehaviorSubject<number>;

  public finalizeReportCartMessage: BehaviorSubject<boolean>;

  public incidentDetails: BehaviorSubject<Incident[]>;

  public imagesData: BehaviorSubject<InfoFieldPhoto[]>;

  constructor() {
    this.currentReportCart = new BehaviorSubject<ReportCart>(null);
    this.alertSubject = new BehaviorSubject<IAlertNotification>(null);
    this.generatingPdf = new BehaviorSubject<ReportGenerateSource>(null);
    this.progressPdf = new BehaviorSubject<number>(0);
    this.finalizeReportCartMessage = new BehaviorSubject<boolean>(false);
    this.incidentDetails = new BehaviorSubject<Incident[]>([]);
    this.imagesData = new BehaviorSubject<InfoFieldPhoto[]>([]);
  }
}

export default StateService;
