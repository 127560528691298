import { createContext, useContext, useLayoutEffect, useState } from "react";
import { ShellConfig } from "@msicie/public-types";
import ccAdminToken$ from "../configureToken";

export const useComponentLoaded = (eventName: string): void => {
  useLayoutEffect(() => {
    window.dispatchEvent(new CustomEvent(eventName));
  }, []);
};

export const useObserveToken = (config: ShellConfig): void => {
  useLayoutEffect(() => {
    const sub = config.session.token$.subscribe((val) =>
      ccAdminToken$.next({ access_token: val })
    );
    return () => sub.unsubscribe();
  }, []);
};

type ThemeMode = "dark" | "light";
export const useObserveTheme = (config: ShellConfig): ThemeMode => {
  const [mode, setMode] = useState<ThemeMode>("dark");
  useLayoutEffect(() => {
    const sub = config.session.theme$.subscribe(setMode);
    return () => sub.unsubscribe();
  }, []);

  return mode;
};

const ShellConfigContext = createContext<ShellConfig | undefined>(undefined);

export const ShellConfigProvider = ShellConfigContext.Provider;

interface ShellConfigHook {
  shellConfig: ShellConfig;
}

export const useShellConfig = (): ShellConfigHook => {
  const shellConfig = useContext(ShellConfigContext);
  return { shellConfig };
};
